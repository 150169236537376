import axios from "@/plugins/http.service.js";

export default {
  getadmins() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`admin/get-admins?tenant_id=${tenant_id}`);
  },
  save(id, fname, lname, email, password) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      id: id,
      email: email,
      fname: fname,
      lname: lname,
      password: password,
      tenant_id,
    };
    return axios.post("admin/save", data);
  },
  add(fname, lname, email, password) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      email: email,
      fname: fname,
      lname: lname,
      password: password,
      tenant_id,
    };
    return axios.post("admin/add-admin", data);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("admin/archive", data);
  },
};
