import api from "./api.js";
export default {
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    tabs: null,
    selectedItem: 1,
    snackbar: false,
    clicked: false,
    text: "",
    id: null,
    fname: "",
    lname: "",
    email: "",
    password: "",
    info: [],
    textProps: {
      "v-model": "msg",
    },
    NormalRules: [(v) => !!v || "Field is required"],
    types: [],
    selected_start_date: null,
    data: [],
    type: null,
    myCurrentData: [],
    infoDialog: false,
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      "First Name": "fname",
      "Last Name": "lname",
      Email: "email",
    },
    headers: [
      {
        text: "First Name",
        value: "fname",
        align: "center",
      },
      {
        text: "Last Name",
        value: "lname",
        align: "center",
      },
      {
        text: "Email",
        value: "email",
        align: "center",
      },

      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    if (this.$route && this.$route.params) {
      const tenantId = this.$route.params.id;
      this.fetch(tenantId);
    }
  },
  methods: {
    fetch(tenantId) {
      this.data = [];
      api
        .gettenantadmins(tenantId)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = res.data.data;
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },
    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.text = "Admin Deleted Succesfully";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        })
        .finally(() => {
          this.loading = false;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    showDialog(item) {
      console.log(this.$route.params.id)
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.fname = "";
        this.lname = "";
        this.email = "";
        this.password = "";

        this.id = null;
      } else {
        this.selectedObject = item;

        this.id = item.id;
        this.fname = item.fname;
        this.lname = item.lname;
        this.email = item.email;
        this.password = item.password;
      }

      this.dialog = true;
    },
    navigateToInfo(item) {
      // Assuming you have Vue Router imported and set up
      this.$router.push(`/tenant/${item.id}/admins`);
    },
    save() {
      this.clicked = true;

      if (!this.$refs.form.validate()) return false;
      this.loading = true;

      if (this.selectedObject.id != -1) {
        api
          .save(this.id, this.fname, this.lname, this.password, this.email)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);

              this.data[index].fname = this.fname;
              this.data[index].lname = this.lname;
              this.data[index].password = this.password;
              this.data[index].email = this.email;

              this.text = "Admin updated successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
          });
      } else {
        const tenantId = this.$route.params.id;

        api
          .add(tenantId, this.fname, this.lname, this.password, this.email)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;

            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                fname: this.fname,
                lname: this.lname,
                password: this.password,
                email: this.email,
              });

              this.text = "Admin added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
  },
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }

        case "lg": {
          return 4;
        }

        case "xl": {
          return 4;
        }
      }
    },
  },
};
