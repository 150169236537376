import axios from "@/plugins/http.service.js";

export default {
  getwarehouses() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`warehouse/get-warehouse?tenant_id=${tenant_id}`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("warehouse/archive", data);
  },
  save(id, title, lat, lon) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      id: id,
      title: title,
      tenant_id,
      lat,
      lon,
    };

    return axios.post("warehouse/save", data);
  },
  add(title, lat, lon) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      title: title,
      tenant_id,
      lat,
      lon,
    };

    return axios.post("warehouse/add", data);
  },
};
