import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`type/get-types?tenant_id=${tenant_id}`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("type/archive", data);
  },
  save(id, title, time_stop) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      id: id,
      title: title,
      time_stop: parseInt(time_stop),
      tenant_id,
    };

    return axios.post("type/save", data);
  },
  add(title, time_stop) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      title: title,
      time_stop: time_stop,
      tenant_id,

    };

    return axios.post("type/add", data);
  },
};
