
  import api from "./api.js";
  import destinationApi from "../destination/api.js";
  import settings from "../../plugins/settings.js";

  var trip_term = localStorage.getItem("trip_term") || "Trip";
  var driver_term = localStorage.getItem("driver_term") || "Driver";
  var destination_term = localStorage.getItem("destination_term") || "Destination";
  var currency = settings.fromNameToSymbol(localStorage.getItem("currency") || "Euro");



  export default {
    data() {
      return {
        // State Management
        tenantType: sessionStorage.getItem("tenant_type") ?? null,
        valid: false,
        dialog: false,
        deleteDialog: false,
        aiDialog: false,
        bigDialog: false,
        originExceptionDialog: true,
        destinationExceptionDialog: false,
        destinationDialog: false,
        addDriverDialog: false,
        addWarehouseDialog: false,
        addBusDialog: false,
        addTripTypeDialog: false,
        


        showTooltip: false,
        clicked: false,
        clear: false,
        continuous: false,
        loading: true,
        driverDisable: true,
        snackbar: false,
        snackbarColor: null,
        tabs: null,
        selectedItem: 1,
        selectedObject: { id: -1 },
        search: "",
        currentDestinationIndex: null,
        fmenu: false,
        tmenu: false,
        show1: false,
        infoDialog: false,
        showStayingTimeRange: false,
        showDrivingTimeRange: false,
        showPassword: false,
        showDriverCombobox: true,
        showWarehouseCombobox: true,
        showBusCombobox: true,
        showTripTypeCombobox: true,
        



        // UI Components
        textProps: { "v-model": "msg" },
        dateProps: { color: "primary" },
        timeProps: { format: "24hr", color: "primary", landscape: true },
        title: "",
        trip_term: trip_term,
        driver_term: driver_term,
        destination_term: destination_term,
        currency: currency,
        searchTermDriver: '',
        searchTermWarehouse: '',
        searchTermBus: '',
        searchTermTripType: '',

        headers: [
          {
            text:
              (sessionStorage.getItem("tenant_type") ?? null) === "namat"
                ? "Staff"
                : "Driver Name",
            value: "driver.fullname",
            align: "center",
          },
          {
            text: "Origin",
            value: "warehouse.title",
            align: "center",
          },
          {
            text: "Type",
            value: "type0.title",
            align: "center",
          },
          {
            text: "Status",
            value: "flag",
            align: "center",
          },
          {
            text: "Created On",
            value: "created_date",
            align: "center",
          },
          {
            text: "Start Date",
            value: "start_date",
            align: "center",
          },
          {
            text: "Picked On",
            value: "started_date",
            align: "center",
          },
          {
            text: "Delivered On",
            value: "ended_date",
            align: "center",
          },
          {
            text: "Accident(s)",
            value: "accidents",
            align: "center",
          },
          {
            text: "Fine(s)",
            value: "fines",
            align: "center",
          },
          {
            text: "Actions",
            value: "actions",
            align: "center",
          },
        ],
        pictures: [],

        // Data Management
        types: [],
        arrivalTimes: [],
        selected_start_date: null,
        currentDestination: null,
        selected_driver: null,
        selected_warehouse: null,
        selected_type: null,
        start_date: null,
        data: [],
        myCurrentData: [],
        warehouses: [],
        languages: [],
        buses: [],
        drivers: [],
        newDriver: {
          fname: '',
          lname: '',
          email: '',
          password: '',
          bus: null,
          hourly_rate: 0,
          language: null,
        },
        newWarehouse: {
          title: null,  
          lat: null,
          lon: null,   
        },

        newBus:{
          reference: null
        },

        newTripType : {
          title: null,
          time_stop: null
        },
      
        fdate: null,
        tdate: null,
        info: null,
        type: null,
        note: "",
        destinations: [],
        destination_id: null,
        trip_destinations: [],
        place: { name: "", lat: null, lon: null },
        departure_time_origin_range: null,
        busesCount: 0,

        // Validation Rules
        NormalRules: [(v) => !!v || "Field is required"],
        NumberRules: [
          (v) => v === null || v === undefined || v === "" || v >= 0 || "Please enter a non-negative number",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length <= 10) || "Password must be less than 10 characters",
          (v) => (v && v.length >= 4) || "Minimum 4 Characters",
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],

        // Others
        snackbarMessage: "",
        json_fields: {
          Id: "id",
          "Driver Name": "driver.fullname",
          Warehouse: "warehouse.title",
          Type: "type.title",
          Status: "flag",
          "Start At": "start_date",
          "Picked On": "started_date",
          "Delivered On": "ended_date",
          "Nb of Accidents": "accidents.length",
          "Nb of Fines": "fines.length",
        },
      };
    },
    mounted() {
      window.addEventListener("keyup", this.handleKeyPress);
      this.initializeData();
      this.fetch();

      if (this.tenantType === "namat") {
        this.headers = this.getNamatHeaders();
      }
    },
    beforeDestroy(){
      window.removeEventListener("keyup", this.handleKeyPress);
    },
    methods: {
      closeAddDriverDialog() {
        this.showDriverCombobox = false;
        this.selected_driver = null;
        this.$nextTick(() => {
          this.showDriverCombobox = true;
        });
        this.addDriverDialog = false;
      },
    
      closeAddBusDialog() {
        this.showBusCombobox = false;
        this.newDriver.bus = null; 
        this.$nextTick(() => {
          this.showBusCombobox = true;
        });
        this.addBusDialog = false;
      },
    
      closeAddWarehouseDialog() {
        this.showWarehouseCombobox = false;
        this.newWarehouse.title = null;
        this.$nextTick(() => {
          this.showWarehouseCombobox = true;
        });
        this.addWarehouseDialog = false;
      },
    
      closeAddTripTypeDialog() {
        this.showTripTypeCombobox = false;
        this.newTripType.title = null;
        this.$nextTick(() => {
          this.showTripTypeCombobox = true;
        });
        this.addTripTypeDialog = false;
      },
    
      
      
      customDriverFilter(item, queryText) {
        if (item.id === -1) return true;
        return item.fullname.toLowerCase().includes(queryText.toLowerCase());
      },
      customWarehouseFilter(item, queryText) {
        if (item.id === -1) return true;
        return item.title.toLowerCase().includes(queryText.toLowerCase());
      },
      customBusFilter(item, queryText) {
        if (item.id === -1) return true;
        return item.reference.toLowerCase().includes(queryText.toLowerCase());
      },

      handleDriverChange(selected) {
        if (selected && selected.id === -1) {
          this.selected_driver = null;
          this.showAddDriverDialog();
        }
      },

      handleWarehouseChange(selected) {
        if (selected && selected.id === -1) {
          this.selected_warehouse = null;
          this.showAddWarehouseDialog();
        }
      },

      handleTripTypeChange(selected) {
        if (selected && selected.id === -1) {
          this.selected_type = null;
          this.showAddTripTypeDialog();
        }
      },

      handleBusChange(selected) {
        if (selected && selected.id === -1) {
          this.newBus.reference = null;
          this.showAddBusDialog();
        }
      },

      showAddTripTypeDialog(){
        this.addTripTypeDialog = true;
        this.newTripType = {
          title: null,
          time_stop: null,
        };
      },

      saveTripType() {
        if (!this.$refs.trip_type_form.validate()) {
          this.snackbarMessage = "Please fill the form correctly";
          this.snackbarColor = "error";
          this.snackbar = true;
          return;
        }
      
        api.addtype(this.newTripType.title, this.newTripType.time_stop)
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.newTripType = {
                id: res.data.data.id,
                ...this.newTripType,
              };
      
              api.gettypes().then(this.handleGetTypes).catch(this.handleError);
      
              this.selected_type = this.newTripType;
      
              this.addTripTypeDialog = false;
      
              this.snackbarMessage = "Trip type added successfully.";
              this.snackbarColor = "success";
              this.snackbar = true;
            } else {
              this.snackbarMessage = res.data.responseMessage || "Failed to add trip type.";
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            // Handle the error case
            this.snackbarMessage = "Error adding trip type: " + error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      },
      

      showAddBusDialog(){
        this.addBusDialog = true;
        this.newBus = {
          reference: null,
        };
      },

      saveBus() {
        this.loading = true;
        if (!this.$refs.bus_form.validate()){
          this.snackbarMessage = "Please fill the form correctly";
           
          this.snackbarColor = "error";
          this.snackbar = true;
          return; 
        }
        api
          .addbus(this.newBus.reference)
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.newDriver.bus = {
                id: res.data.data.id,
                reference: this.newBus.reference,
                statusText: "No Assigned",
                driverFullName: "N/A",
                drivers: [],
              };
              api
                .getbuses()
                .then((res) => {
                  if (res.data.responseCode === 1) {
                    this.buses = res.data.data;
                    this.busesCount = res.data.data.length;
                    if (this.busesCount < this.buses.length) this.buses.pop();
                  }
                })
                .catch((error) => {
                  this.snackbarMessage = "Error fetching buses: " + error;
                  this.snackbarColor = "error";
                  this.snackbar = true;
                });
              this.snackbarMessage = "Vehicle added successfully.";
              this.snackbarColor = "success";
              this.snackbar = true;
              this.addBusDialog = false;
            } else {
              this.snackbarMessage = res.data.responseMessage || "Failed to add vehicle.";
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarMessage = "Error adding vehicle: " + error;
            this.snackbarColor = "error";
            this.snackbar = true;
          })
          .finally(() => {
            this.loading = false;
          });
      },
  

      showAddWarehouseDialog(){
        this.addWarehouseDialog = true;
        this.newWarehouse = {
          title: null,
          lat: null,
          lon: null,
        }
      },

      onPlaceSelectedWarehouse(place) {
        console.log(place);
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        this.newWarehouse.title = place.formatted_address;
        this.newWarehouse.lat = latitude;
        this.newWarehouse.lon = longitude;
      },

      saveWarehouse() {
        const { title, lat, lon } = this.newWarehouse;
        if (title == null){
          this.snackbarMessage = "Please insert the origin";
          this.snackbarColor = "error";
          this.snackbar = true;
          return; 
        }
        
        api
          .addwarehouse(title, lat, lon)
          .then((res) => {
            if (res.data.responseCode === 1) {
              const newWarehouse = {
                id: res.data.data.id,
                title,
                lat,
                lon,
              };
              this.warehouses.push(newWarehouse);
              this.selected_warehouse = newWarehouse;
              this.snackbarMessage = "Origin added successfully.";
              this.snackbarColor = "success";
              this.snackbar = true;
              this.addWarehouseDialog = false;
            } else {
              this.snackbarMessage = res.data.responseMessage || "Failed to add origin.";
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarMessage = "Error adding origin: " + error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      },
    
      
      showAddDriverDialog() {
        this.addDriverDialog = true;
        api
          .getbuses()
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.buses = res.data.data;
              this.busesCount = res.data.data.length;
              if (this.busesCount < this.buses.length) this.buses.pop();
            }
          })
          .catch((error) => {
            this.snackbarMessage = "Error fetching buses: " + error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
        api
          .getlanguages()
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.languages = res.data.data;
            }
          })
          .catch(() => {
            this.snackbarMessage = "Failed to fetch languages.";
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      },
      saveDriver() {
        if (!this.$refs.driver_form.validate()){
          this.snackbarMessage = "Please fill the form correctly";
           
          this.snackbarColor = "error";
          this.snackbar = true;
          return; 
        }
        const { fname, lname, email, password, bus, hourly_rate, language } = this.newDriver;
        const bus_id = bus?.id;
        api
          .add_driver(fname, lname, email, password, bus_id, hourly_rate, language)
          .then((response) => {
            if (response.data.responseCode === 1) {
              this.addDriverDialog = false;
              const newDriver = {
                id: response.data.data.id,
                fullname: `${fname} ${lname}`,
                email,
                bus,
                hourly_rate,
                language,
              };
              this.drivers.push(newDriver);
              this.selected_driver = newDriver;
              this.snackbarMessage = `${driver_term} added successfully.`;
              this.snackbarColor = "success";
              this.snackbar = true;
            } else {
              this.snackbarMessage = response.data.responseMessage || `Failed to add ${driver_term}.`;
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarMessage = `Error adding ${driver_term}: ` + error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      },

      handleKeyPress(event) {
        if (event.key === "Enter") {
          switch (true) {
            case this.addBusDialog:
              this.saveBus();
              return;
            case this.addDriverDialog:
              this.saveDriver();
              return;
            case this.addWarehouseDialog:
              this.saveWarehouse();
              return;
            case this.addTripTypeDialog:
              this.saveTripType();
              return;
            case this.dialog:
              this.save();
              return;
            case this.deleteDialog:
              this.agreeOnDelete();
              return;
            default:
              break;
          }
        }
      },
      
      initializeData() {
        const fromDate = new Date();
        const toDate = new Date();
        fromDate.setDate(fromDate.getDate() - 3);
        toDate.setDate(toDate.getDate() + 3);
        this.fdate = fromDate.toISOString().substr(0, 10);
        this.tdate = toDate.toISOString().substr(0, 10);

        api.gettypes().then(this.handleGetTypes).catch(this.handleError);
        api.getwarehouses().then(this.handleGetWarehouses).catch(this.handleError);
      },
      getNamatHeaders() {
        return [
          {
            text: "Staff",
            value: "driver.fullname",
            align: "center",
          },
          {
            text: "Origin",
            value: "warehouse.title",
            align: "center",
          },
          {
            text: "Type",
            value: "type0.title",
            align: "center",
          },
          {
            text: "Status",
            value: "flag",
            align: "center",
          },
          {
            text: "Created On",
            value: "created_date",
            align: "center",
          },
          {
            text: "Start Date",
            value: "start_date",
            align: "center",
          },
          {
            text: "Actions",
            value: "actions",
            align: "center",
          },
        ];
      },
      handleGetTypes(res) {
        if (res.data.responseCode === 1) {
          this.types = res.data.data;
        }
      },
      handleGetWarehouses(res) {
        if (res.data.responseCode === 1) {
          this.warehouses = res.data.data.map((element) => ({
            id: element.id,
            title: element.title,
          }));
        }
      },
      handleError(error) {
        this.error = error;
        this.errorDialog = true;
      },
      async fetch() {
        this.loading = true;
  
        try {
          const user_type = this.tenantType;
          const res = await api.gettrips(this.fdate, this.tdate);
          if (res.data.responseCode === 1) {
            this.data = res.data.data.map((element) => {
              const driver = element.driver
                ? {
                    fullname: `${element.driver.fname} ${element.driver.lname}`,
                    id: element.driver.id,
                  }
                : { fullname: "N/A", id: -1 };
              const warehouse = {
                title: element.warehouse.title,
                id: element.warehouse.id,
              };
              const flag = this.getFlagStatus(element.flag, element.ended_date, user_type);
              const status = this.getStatus(element.started_date, element.start_date);
  
              return {
                ...element,
                driver,
                warehouse,
                flag,
                status,
                started_date: this.formatDate(element.started_date),
                created_date: this.formatDate(element.created_date),
                start_date: this.formatDate(element.start_date),
                ended_date: this.formatDate(element.ended_date),
              };
            });
          }
  
          const destRes = await destinationApi.getdestinations();
          if (destRes.data.responseCode === 1) {
            this.destinations = destRes.data.data;
          }
        } catch (error) {
          this.snackbarMessage = "Error fetching data: " + error;
          this.snackbarColor = "error";
          this.snackbar = true;
        } finally {
          this.loading = false;
        }
      },
      getFlagStatus(flag, ended_date, user_type) {
        if (flag === 0) {
          return user_type === "namat" ? "Assigned" : "Not Picked";
        } else if (ended_date) {
          return user_type === "namat" ? "Done" : "Delivered";
        } else {
          return user_type === "namat" ? "In progress" : "Picked";
        }
      },
      getStatus(started_date, start_date) {
        if (started_date != null) {
          return new Date(started_date) > new Date(start_date) ? 1 : 2;
        } else {
          return 0;
        }
      },
      handleIconClick(from, index = null) {
        if (from === "origin") {
          this.destinationDialog = false;
          this.originExceptionDialog = true;
          this.currentDestination = null;
          this.currentDestinationIndex = null;
        } else if (index !== null && this.trip_destinations[index]) {
          this.currentDestinationIndex = index;
          this.currentDestination = this.trip_destinations[index];
          this.destinationExceptionDialog = true;
          this.destinationDialog = false;
          this.originExceptionDialog = false;
        } else {
          console.error("Destination not found or invalid index provided.");
        }
      },

      parseDate(dateString) {
        const [datePart, timePart] = dateString.split(", ");
        const [day, month, year] = datePart.split("/").map(Number);
        const [hours, minutes, seconds] = timePart.split(":").map(Number);

        return new Date(year, month - 1, day, hours, minutes, seconds);
      },
      getFiltered(e) {
        this.myCurrentData = e;
      },
      back() {
        this.$nextTick(() => {
          this.tabs = "tab-1";
        });
      },
      show(item) {
        this.pictures = item.pictures.map((element) => ({
          src:
            this.type === 0
              ? `${this.$imgurl}fines/${element.title}`
              : `${this.$imgurl}accidents/${element.title}`,
        }));
        this.$nextTick(() => {
          this.tabs = "tab-2";
        });
      },
      focus(type, info) {
        this.$nextTick(() => {
          this.tabs = "tab-1";
        });
        this.type = type;
        this.info = info;
        this.infoDialog = true;
      },
      formatDate(dateInput) {
        if (!dateInput) return null;
      
        let date;
      
        if (typeof dateInput === 'string') {
          const [datePart, timePart] = dateInput.split(' ');
          if (!datePart || !timePart) return null;
      
          const [year, month, day] = datePart.split('-').map(Number);
          const [hours, minutes, seconds] = timePart.split(':').map(Number);
      
          date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
        } else if (dateInput instanceof Date) {
          date = dateInput;
        } else {
          return null;
        }
      
        return date.toLocaleString();
      },
      
      pad(number) {
        return number < 10 ? "0" + number : number;
      },
      onDelete(item) {
        this.selectedObject = item;
        this.deleteDialog = true;
      },
      async agreeOnDelete() {
        this.clicked = true;
        this.loading = true;
        try {
          const res = await api.archive(this.selectedObject.id);
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.data = this.data.filter((item) => item.id !== this.selectedObject.id);
            this.snackbarMessage = "Trip deleted successfully.";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.snackbarMessage = res.data.responseMessage || "Failed to delete trip.";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        } catch (error) {
          this.snackbarMessage = "Error deleting trip: " + error;
          this.snackbarColor = "error";
          this.snackbar = true;
        } finally {
          this.loading = false;
          this.clicked = false;
        }
      },
      async showAiDialog() {
        this.aiDialog = true;
      },
      async showDialog(item) {
        this.valid = true;
        if (item == null) {
          this.resetFormData();
        } else {
          this.populateFormData(item);
        }
        this.arrivalTimes.push(["origin_start_time", new Date(this.selected_start_date)]);
        this.dialog = true;
      },
      resetFormData() {
        this.selectedObject = { id: -1 };
        this.selected_driver = null;
        this.selected_warehouse = null;
        this.selected_type = null;
        this.id = null;
        this.selected_start_date = new Date();
        this.place = { name: null, lat: null, lon: null };
        this.trip_destinations = [];
        this.note = null;
        this.departure_time_origin_range = null;
      },
      populateFormData(item) {
        this.selectedObject = item;
        this.selected_warehouse = {
          id: item.warehouse.id,
          title: item.warehouse.title,
        };
        this.selected_driver = {
          id: item.driver.id,
          fullname: item.driver.fullname,
        };
        this.selected_type = {
          id: item.type0.id,
          title: item.type0.title,
        };
        this.id = item.id;
        this.selected_start_date = this.parseDate(item.start_date);
        this.note = item.note;
        this.destination_id = item.destination_id;
        this.place = {
          name: item.destination_name,
          lat: item.destination_lat,
          lon: item.destination_lon,
        };
        this.trip_destinations = item.destinations.map((des) => ({
          destination_id: des.destination_id,
          destination_lat: des.destination?.lat || des.destination_lat,
          destination_lon: des.destination?.lon || des.destination_lon,
          destination_name: des.destination_name,
          task: des.task,
          staying_time: des.staying_time ?? null,
          driving_time: des.driving_time ?? null,
          staying_time_range: des.staying_time_range ?? null,
          driving_time_range: des.driving_time_range ?? null,
          client_approval: des.client_approval,
          manager_approval: des.manager_approval,
        }));
        this.departure_time_origin_range = item.departure_time_origin_range;
      },
      async save() {
        this.clicked = true;
  
        let ok = true;
        this.trip_destinations.forEach((destination) => {
          if (!destination.destination_name) {
            ok = false;
          }
        });
  
        if (!this.$refs.form.validate() || !ok) {
          this.snackbarMessage = ok
            ? "Please fill the form correctly"
            : "Fill the " + destination_term + " correctly";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.clicked = false;
          return;
        }
  
        this.loading = true;
        try {
          let success = false;
          if (this.selectedObject.id !== -1) {
            success = await this.updateTrip();
          } else {
            success = await this.addTrip();
          }
          if (success) {
            this.dialog = false;
            this.resetData();
            this.fetch();
          }
        } catch (error) {
          this.snackbarMessage = "Error saving trip: " + error;
          this.snackbarColor = "error";
          this.snackbar = true;
        } finally {
          this.loading = false;
          this.clicked = false;
        }
      },


      async updateTrip() {
      const res = await api.save(
        this.id,
        this.selected_driver.id,
        this.selected_warehouse.id,
        this.selected_type.id,
        this.convertToUTCDate(this.selected_start_date),
        this.note,
        this.trip_destinations,
        this.departure_time_origin_range
      );
      if (res.data.responseCode === 1) {
        const index = this.data.findIndex((item) => item.id === this.selectedObject.id);
        if (index !== -1) {
          this.data[index] = {
            ...this.data[index],
            driver: {
              id: this.selected_driver.id,
              fullname: this.selected_driver.fullname,
            },
            warehouse: {
              id: this.selected_warehouse.id,
              title: this.selected_warehouse.title,
            },
            type0: this.selected_type,
            start_date: this.formatDate(this.selected_start_date),
            departure_time_origin_range: this.departure_time_origin_range,
            trip_destinations: this.trip_destinations,
          };
        }
        this.snackbarMessage = "Trip updated successfully.";
        this.snackbarColor = "success";
        this.snackbar = true;
        return true;
      } else {
        this.snackbarMessage = res.data.responseMessage || "Failed to update trip.";
        this.snackbarColor = "error";
        this.snackbar = true;
        return false;
      }
    },
    async addTrip() {
      const res = await api.add(
        this.selected_driver.id,
        this.selected_warehouse.id,
        this.selected_type.id,
        this.convertToUTCDate(this.selected_start_date),
        this.note,
        this.trip_destinations,
        this.departure_time_origin_range
      );
      if (res.data.responseCode === 1) {
        this.data.push({
          id: res.data.data.id,
          driver: {
            id: res.data.data.driver_id,
            fullname: this.selected_driver.fullname,
          },
          warehouse: {
            id: res.data.data.warehouse_id,
            title: this.selected_warehouse.title,
          },
          type0: this.selected_type,
          start_date: this.formatDate(this.selected_start_date),
          flag: "Not Picked",
          created_date: this.formatDate(new Date()),
          ended_date: null,
          started_date: null,
          accidents: [],
          fines: [],
        });
        this.snackbarMessage = "Trip added successfully.";
        this.snackbarColor = "success";
        this.snackbar = true;
        return true;
      } else {
        this.snackbarMessage = res.data.responseMessage || "Failed to add trip.";
        this.snackbarColor = "error";
        this.snackbar = true;
        return false;
      }
    },
      onPlaceSelected(place, index) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        this.trip_destinations[index] = {
          ...this.trip_destinations[index],
          destination_id: null,
          destination_name: place.formatted_address,
          destination_lat: latitude,
          destination_lon: longitude,
        };
        this.currentDestination = this.trip_destinations[index];
      },
      onPlaceInputChange(place, index) {
        this.trip_destinations[index] = {
          ...this.trip_destinations[index],
          destination_name: place || null,
          destination_lat: null,
          destination_lon: null,
        };
      },
      closeDialog() {
        this.dialog = false;
        this.resetData();
      },
      addDestination() {
        this.currentDestination = {
          destination_id: null,
          destination_name: null,
          destination_lat: null,
          destination_lon: null,
          task: null,
          staying_time: null,
          driving_time: null,
          staying_time_range: null,
          driving_time_range: null,
          client_approval: false,
          manager_approval: false,
        };
        this.destinationDialog = true;
        this.trip_destinations.push(this.currentDestination);
        this.currentDestinationIndex = this.trip_destinations.length - 1;
      },
      removeDestination(index) {
        this.trip_destinations.splice(index, 1);
        this.calculateArrivalTimes();
        if (this.trip_destinations.length === 0) {
          this.destinationDialog = false;
          this.currentDestination = null;
          this.currentDestinationIndex = null;
        } else {
          this.currentDestinationIndex = index > 0 ? index - 1 : 0;
          this.currentDestination = this.trip_destinations[this.currentDestinationIndex];
        }
      },
      updateDestinationName(destination) {
        if (!destination.destination_id) {
          destination.destination_name = "";
          this.clear = true;
          return;
        }
        this.clear = false;

        const selectedDestination = this.destinations.find(
          (dest) => dest.id === destination.destination_id
        );

        if (selectedDestination) {
          destination.destination_name = selectedDestination.name;
        }
      },
      editDestination(destination, index) {
        this.originExceptionDialog = false;
        this.destinationDialog = true;
        this.currentDestination = destination;
        this.currentDestinationIndex = index;
      },
      resetData() {
        this.destinationDialog = false;
        this.currentDestination = null;
        this.currentDestinationIndex = null;
        this.selected_type = null;
        this.destination_id = null;
        this.note = "";
        this.place = { name: "", lat: null, lon: null };
      },
      calculateArrivalTimes() {
        this.arrivalTimes = [];
        let startTime = new Date(this.selected_start_date);
        this.arrivalTimes.push(["origin_start_time", new Date(startTime)]);

        this.trip_destinations.forEach((destination, index) => {
          const drivingTimeMinutes = parseInt(destination.driving_time, 10);
          const stayingTimeMinutes = parseInt(destination.staying_time, 10);

          if (
            !isNaN(drivingTimeMinutes) &&
            drivingTimeMinutes !== 0 &&
            !isNaN(stayingTimeMinutes) &&
            stayingTimeMinutes !== 0
          ) {
            startTime = new Date(startTime.getTime() + drivingTimeMinutes * 60000);
            startTime = new Date(startTime.getTime() + stayingTimeMinutes * 60000);
            this.arrivalTimes.push([index, new Date(startTime)]);
          } else {
            this.arrivalTimes.push([index, null]);
          }
        });

        this.continuous = this.arrivalTimes.slice(0, -1).every((item) => item[1] !== null);
      },
      getExpectedArrivalTime(index, stayingTime) {
        const arrivalTime = this.arrivalTimes[index + 1][1];
        if (arrivalTime && !isNaN(stayingTime)) {
          const adjustedArrivalTime = new Date(arrivalTime.getTime() - stayingTime * 60000);
          return adjustedArrivalTime.toLocaleString();
        }
        return "N/A";
      },
      async updateDrivers() {
        try {
          const res = await api.getdrivers();
          if (res.data.responseCode === 1) {
            this.drivers = res.data.data
              .filter((element) => element.archived !== 1)
              .map((element) => ({
                id: parseInt(element.id),
                fullname: `${element.fname} ${element.lname}`,
              }));
            this.driverDisable = false;
          }
        } catch (error) {
          this.text = error;
          this.snackbar = true;
          this.errorDialog = true;
        }
      },
      convertToUTCDate(date) {
        const utcDate = new Date(date || new Date());
        return utcDate.toISOString().replace("T", " ").substr(0, 19);
      },
      toggleStayingTimeRange() {
        if (this.currentDestination) this.showStayingTimeRange = !this.showStayingTimeRange;
      },
      toggleDrivingTimeRange() {
        if (this.currentDestination) {
          this.showDrivingTimeRange = !this.showDrivingTimeRange;
        }
      },
    },
    watch: {
      trip_destinations: {
        handler() {
          this.calculateArrivalTimes();
        },
        deep: true,
      },
      selected_start_date() {
        this.calculateArrivalTimes();
        this.updateDrivers();
      },
    },
    computed: {
     
        filteredDrivers() {
          const searchTermDriver = this.searchTermDriver ? this.searchTermDriver.toLowerCase() : '';  // Ensure searchTerm is a string
          const drivers = this.drivers.filter(driver => driver?.fullname?.toLowerCase().includes(searchTermDriver)); // Add null checks
      
          if (searchTermDriver && !drivers.length) {
            return [...drivers, { id: -1, fullname: `Create a new ${this.driver_term}` }];
          }
          return drivers;
        },

        filteredWarehouses() {
          const searchTermWarehouse = this.searchTermWarehouse ? this.searchTermWarehouse.toLowerCase() : '';  // Ensure searchTermWarehouse is a string
          const warehouses = this.warehouses.filter(warehouse => warehouse?.title?.toLowerCase().includes(searchTermWarehouse)); // Add null checks
      
          if (searchTermWarehouse && !warehouses.length) {
            return [...warehouses, { id: -1, title: `Create a new origin` }];
          }
          return warehouses;
        },

        filteredTripTypes() {
          const searchTermTripType = this.searchTermTripType ? this.searchTermTripType.toLowerCase() : '';  // Ensure searchTermTripType is a string
          const trip_types = this.types.filter(type => type?.title?.toLowerCase().includes(searchTermTripType)); // Add null checks
      
          if (searchTermTripType && !trip_types.length) {
            return [...trip_types, { id: -1, title: `Create a new ${trip_term} type` }];
          }
          return trip_types;
        },

        filteredBuses() {
          const searchTermBus = this.searchTermBus ? this.searchTermBus.toLowerCase() : '';  // Ensure searchTermBus is a string
          const buses = this.buses.filter(bus => bus?.reference?.toLowerCase().includes(searchTermBus)); // Add null checks
      
          if (searchTermBus && !buses.length) {
            return [...buses, { id: -1, title: `Create a new vehicle` }];
          }
          return buses;
        },
      
      
      
      Column() {
        const sizeMap = {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
        };
        return sizeMap[this.$vuetify.breakpoint.name] || 12;
      },
    },
  };
