import axios from "@/plugins/http.service.js";

export default {
  getBusRequests() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`bus-request/get-bus-requests?tenant_id=${tenant_id}`);
  },
  accept(id) {
    var data = {
      id: id,
    };
    return axios.post("bus-request/accept-request", data);
  },
  decline(id) {
    var data = {
      id: id,
    };
    return axios.post("bus-request/decline-request", data);
  },
  swap(id) {
    var data = {
      id: id,
    };
    return axios.post("bus-request/swap-request", data);
  },
  getimages(action_id) {
    var data = {
      action_id: action_id,
    };
    return axios.post("bus-request/get-images", data);
  },
  getManagerApproval() {
    return axios.get("v1/trips/destinations/approvals");
  },
  updateManagerRequest(trip_destination_id, status) {
    return axios.patch("trip/update-approval-status", {
      trip_destination_id,
      status,
    });
  },
};
