import axios from "@/plugins/http.service.js";

export default {
  saveFile(data) {
    return axios.post("drivers-files/save-file", data);
  },
  getFile(data) {
    return axios.post("drivers-files/get-file", data);
  },
  deleteFile(data) {
    return axios.post("drivers-files/delete-file", data);
  },
  sentFile(data) {
    return axios.post("drivers-files/send-file", data);
  },
  getFinance() {
    return axios.post("settings/finance-email");
  },
  EditFile(data) {
    return axios.post("drivers-files/edit-file", data);
  },
};
