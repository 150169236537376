import axios from "@/plugins/http.service.js"

export default {
    getLogs(fdate) {
        var data = {
            'fdate': fdate
        }
        return axios.post("log/get-logs", data);
    },
    archive(id) {
        var data = {
            'id': id
        }
        return axios.post('log/archive', data);
    },

};