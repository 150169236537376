import axios from "@/plugins/http.service.js";

export default {
  getaccidents(fdate, tdate) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
    };
    return axios.post(`accident/get-accidents?tenant_id=${tenant_id}`, data);
  },
  getimages(action_id) {
    var data = {
      action_id: action_id,
    };
    return axios.post("accident/get-images", data);
  },
};
