<template>
  <v-app>

  <router-view> </router-view>

    
 
  </v-app>
</template>
<script>
export default {
  name: "homelayout",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.PageLogo {
  display: inline-block;
}
a {
  text-decoration: none;
  text-align: left;
  font-weight: 400;
  color: #333333 !important;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a:hover {
  border-bottom: 1px solid orange;
}
.NavContainer {
  padding-top: 2%;
  padding-bottom: 2%;
}

.SpecialLink {
  padding: 15px;
  background-color: orange;
  border-radius: 10px;
  color: white !important;
}
</style>