import api from "./api.js";
export default {
  data: () => ({
    tenantType: (sessionStorage.getItem("tenant_type") ?? null),
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    title: null,
    search: "",
    text: "",
    id: null,
    snackbar: false,
    clicked: false,
    place: null,
    name: "",
    lat: null,
    lon: null,
    myCurrentData: [],
    json_fields: {
      Id: "id",
      Name: "title",
    },

    NormalRules: [(v) => !!v || "Field is required"],
    data: [],
    headers: [
      {
        text: "Name",
        align: "center",
        value: "title",
      },

      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    api
      .getwarehouses()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          this.data = res.data.data;
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if(this.dialog) this.save();
        if(this.deleteDialog) this.agreeOnDelete();
      }
      
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.valid = true;

      if (item == null) {
        this.selectedObject = { id: -1 };
        this.name = null;

        this.id = null;
      } else {
        this.selectedObject = item;
        this.name = item.title;

        this.id = item.id;
      }
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.text = "Origin deleted succesfully.";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.text = error;
          this.snackbar = true;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    save() {
      this.clicked = true;
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return;
      }
      this.loading = true;

      if (this.place === null) {
        this.lat = null;
        this.lon = null;
      }

      if (this.selectedObject.id != -1) {
        api
          .save(this.id, this.name, this.lat, this.lon)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);
              this.data[index].title = res.data.data.title;

              this.text = "Origin updated successfully";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      } else {
        api
          .add(this.name, this.lat, this.lon)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                title: res.data.data.title,
              });
              this.text = "Origin added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
    onPlaceSelected(place) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.name = place.formatted_address;
      this.lat = latitude;
      this.lon = longitude;
      this.place = place;
    },
  },
};
