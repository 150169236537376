import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_c(VIcon,{staticClass:"mainpageicon",attrs:{"fab":"","large":""}},[_vm._v(_vm._s(this.$route.meta.mainicon))]),_vm._v(" "+_vm._s(this.tenantType === "namat" ? "Task types" : this.$route.meta.title.replace("Trip", _vm.trip_term))+" ")],1)]),_c('div',{staticClass:"headerbuttons"},[_c('div',{staticClass:"btn"},[_c(VBtn,{on:{"click":function($event){return _vm.showDialog(null)}}},[_vm._v(" Add ")])],1),_c('div',{staticClass:"btn1"},[_c('export-excel',{attrs:{"data":_vm.myCurrentData,"fields":_vm.json_fields,"worksheet":_vm.trip_term + 'Types',"name":_vm.trip_term + '_types.xls'}},[_c(VBtn,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-file-excel")]),_vm._v(" Export ")],1)],1)],1)])]),_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"sort-by":"id","sort-desc":true},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{on:{"click":function($event){return _vm.showDialog(item)}}},[_vm._v("edit")]),_c(VIcon,{on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("delete")])]}}],null,true)},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.selectedObject.id == -1 ? "Add" : "Update")+" "+_vm._s(this.tenantType === "namat" ? "task type" : _vm.trip_term + " type"))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"rules":_vm.NormalRules,"label":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{staticStyle:{"width":"100%"},attrs:{"label":"Unauthorized stop time","clearable":"","hide-details":"auto","type":"number","suffix":"minutes","rules":_vm.NumberRules},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("Maximum time for an unauthorized stop")])])]},proxy:true}]),model:{value:(_vm.time_stop),callback:function ($$v) {_vm.time_stop=$$v},expression:"time_stop"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"btn1",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c(VBtn,{staticClass:"btn",attrs:{"disabled":_vm.clicked,"text":""},on:{"click":_vm.save}},[_vm._v("save")])],1)],1)],1)],1),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Confirmation Required ")]),_c(VCardText,[_vm._v("Are you sure you want to delete this "+_vm._s(this.tenantType === "namat" ? "Task type" : _vm.trip_term + " type")+", This action cannot be undone!")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"btn1",attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"btn",attrs:{"disabled":_vm.clicked,"text":""},on:{"click":_vm.agreeOnDelete}},[_vm._v(" Confirm ")])],1)],1)],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }