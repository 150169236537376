import axios from "@/plugins/http.service.js";

export default {
  getSettings() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    return axios.post(`tenant-settings/get-settings`, { tenant_id });
  },



  save_all_settings(data) {
    return axios.post("tenant-settings/save-all", { data: data });
  },
};
