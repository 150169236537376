import axios from "@/plugins/http.service.js";

export default {
  getdrivers() {
    return axios.post("driver/get-drivers");
  },
  
  GetDriversRecentLocations(date) {
    var data = {
      date: date,
    };
    return axios.post("driver/get-drivers-recent-location", data);
  },

  GetDriverLocationsForToday(id, date) {
    var data = {
      id: id,
      date: date,
    };
    return axios.post("driver/get-driver-locations-for-today", data);
  },
};
