import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c(VCard,{staticStyle:{"max-height":"440px","min-height":"440px","padding":"20px"}},[_c(VCardTitle,[_vm._v("Demand vs Capacity")]),_c('BarChart',{staticStyle:{"max-height":"336px"},attrs:{"id":"my-chart-id","options":_vm.chartOptions,"data":_vm.chartData}})],1),_c(VCard,{staticStyle:{"max-height":"440px","min-height":"440px","padding":"20px"}},[_c(VCardTitle,[_vm._v("Job Accomplishments Today")]),_c('LineChart',{staticStyle:{"max-height":"336px"},attrs:{"id":"my-line-id","options":_vm.chartOptions,"data":_vm.lineChartData}})],1),_c(VCard,[_c(VCardTitle,[_vm._v("Pending Tasks")]),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.pendingTasks,"search":_vm.search,"sort-by":"id","sort-desc":false},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.showTaskDetails(item)}}},[_vm._v("perm_media")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }