import axios from "@/plugins/http.service.js";

export default {
  getdrivers() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.get(`driver/get-drivers?tenant_id=${tenant_id}`);
  },

  getbuses() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      type: 1,
    };
    return axios.post(`bus/get-buses?tenant_id=${tenant_id}`, data);
  },

  add(fname, lname, email, password, bus_id, hourly_rate, language) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      fname: fname,
      lname: lname,
      email: email,
      password: password,
      bus_id: bus_id,
      hourly_rate: hourly_rate,
      tenant_id,
      language,
    };
    return axios.post("driver/add-driver", data);
  },

  save(
    id,
    fname,
    lname,
    email,
    password,
    old_bus_id,
    bus_id,
    hourly_rate,
    language
  ) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    var data = {
      id: id,
      fname: fname,
      lname: lname,
      email: email,
      password: password,
      old_bus_id: old_bus_id,
      bus_id: bus_id,
      hourly_rate: hourly_rate,
      tenant_id,
      language,
    };
    return axios.post("driver/save-web", data);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("driver/archive", data);
  },

  addbus(reference) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      reference: reference,
      tenant_id,
    };

    return axios.post("bus/add", data);
  },
};
