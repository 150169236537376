<template>
  <div id="parenty" style="height:100%;background: #F8F8F8;">
    <v-container style="height:100%">
      <router-view> </router-view>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "defaultlayout",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>

</style>