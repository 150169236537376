import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    id: null,
    email: null,
  },
  mutations: {
    savedata(state, { id, email, tenant_id, is_super_admin, tenant_name, tenant_type }) {
      state.id = id;
      state.email = email;
      sessionStorage.setItem("cooriroo_admin_id", id);
      sessionStorage.setItem("cooriroo_tenant_id", tenant_id);
      sessionStorage.setItem("cooriroo_admin_email", email);
      sessionStorage.setItem("is_super_admin", is_super_admin);
      sessionStorage.setItem("tenant_name", tenant_name);
      sessionStorage.setItem("tenant_type", tenant_type);
    },
  },
  actions: {
    async login({ commit }, { id, email, tenant_id, is_super_admin, tenant_name, tenant_type }) {
      commit("savedata", { id, email, tenant_id, is_super_admin, tenant_name, tenant_type });
    },
  },
  modules: {},
});
