import api from "./api.js";

export default {
  data() {
    return {
      content: {},
      sections: ['hero_section', 'detail_section', 'about_section','feature_section', 'software_section', 'demo_section', 'contact_section' ],
      loading: false,
      contentError: false,
      text: null,
      errorText: null,
      fullName: null,
      email: null,
      changeBackgroundColor: false,
      phoneNumber: null,
      companySize: null,
      discuss: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: [(v) => !!v || "Field is required"],
      drawer: false,
      webStyle:
        "text-align: center !important;font-size: 54px !important;color: #333333 !important;font-weight: bold !important;",
      phoneStyle:
        "text-align: center !important;font-size: 32px !important;color: #333333 !important;font-weight: bold !important;",
      webStyle1:
        "text-align: left !important;font-size: 54px !important;color: #333333 !important;font-weight: bold !important;",
      phoneStyle1:
        "text-align: left !important;font-size: 32px !important;color: #333333 !important;font-weight: bold !important;",
      industries: [
        { 
          name: 'School Bus Fleet Management', 
          path: '/schoolBus',
          description: "Efficiently manage your school bus fleet with Cooriroo's AI-powered platform. Ensure safe routes, real-time tracking of vehicles, and instant communication with drivers. Perfect for schools and educational institutions focused on safety and reliability." 
        },
        { 
          name: ' Last Mile Delivery Solutions', 
          path: '/',
          description: "Optimize your last-mile delivery operations with Cooriroo. Our advanced fleet management tools streamline deliveries, reduce fuel consumption, and improve customer satisfaction with real-time updates. Ideal for e-commerce and courier services."
        },
        { 
          name: 'Construction Fleet Management', 
          path: '/',
          description: "Maximize the productivity of your construction fleet with Cooriroo. Track heavy equipment, monitor fuel usage, and ensure timely project delivery with robust reporting and AI-driven insights. Tailored for the construction industry’s unique challenges."
        },
        {
          name: 'Installation Services Fleet Management', 
          path: '/',
          description: "Streamline your device and equipment installation operations with Cooriroo. Manage multiple teams, track vehicle locations, and ensure timely installations with real-time updates and centralized data management. Perfect for companies focused on device or system installations."
        }
      ],
    };
  },
  
  beforeMount(){
    this.resetIcon();
    api.getContent().then((res) => {
      var data = res.data.data;
      this.sections.forEach((section) => {
        this.content[section] = data[section];
      });
      this.$forceUpdate(); 
    }).catch(() => {
      //TODO: add contentError panel
      this.contentError = true;
    });
  },
  methods: {
    resetIcon(){
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = "/favicon.svg"; 
    },
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while (obj === obj.offsetParent);
        return [curtop];
      }
    },
    scroll(destination, demo = false) {
      if (this.drawer == true) this.drawer = false;
      window.scroll(0, this.findPos(document.getElementById(destination)));
      if(demo){
        this.discuss = 'Hello, I would like to request a demonstration.';
      }
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        api
          .submit(
            this.fullName,
            this.email,
            this.companySize,
            this.phoneNumber,
            this.discuss
          )
          .then((res) => {
            this.loading = false;
            if (res.data.responseCode === 1) {
              this.$refs.form.reset();
              this.text = "Your message has been sent successfully";
            }
          })
          .catch((error) => {
            this.errorText = error;
            this.loading = false;
          });
      }
    },
    handleLogoClick() {
      if (this.$route.path === '/') {
        
        window.scrollTo({
          top: 0,
          behavior: 'smooth', 
        });
      }
    },
  },
  computed: {
    chunkedData() {
      const chunks = [];
      const data = this.content.feature_section.data;
      for (let i = 0; i < data.length; i += 2) {
        chunks.push(data.slice(i, i + 3));
      }
      return chunks;
    },
    fontSize() {},
    homeFooterItemCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        case "md":
        case "lg":
        case "xl":
          return 4;
      }
    },
    softwareCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return 12;
        case "lg":
        case "xl":
          return 6;
      }
    },
    AboutItemCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        case "md":
        case "lg":
        case "xl":
          return 6;
      }
    },
    cardCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        case "md":
        case "lg":
        case "xl":
          return 4;
      }
    },
    formCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        case "md":
        case "lg":
        case "xl":
          return 6;
      }
    },
    demoBriefCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 12;
        case "md":
        case "lg":
        case "xl":
          return 6;
      }
    },
    breakPoint() {
      return this.$vuetify.breakpoint.name;
    },
    navCol() {
      if (this.$vuetify.breakpoint.width >= 1023) {
        return 6;
      } else {
        return 12;
      }
    },
  },
};
