import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.sendAllDialog),callback:function ($$v) {_vm.sendAllDialog=$$v},expression:"sendAllDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Send File")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{ref:"emailField",staticStyle:{"margin-right":"10px"},attrs:{"rules":[
                function (v) { return !!v || 'E-mail is required'; },
                function (v) { return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'; }
              ],"hide-details":"auto","label":"To"},model:{value:(_vm.Email),callback:function ($$v) {_vm.Email=$$v},expression:"Email"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"btn1",attrs:{"text":""},on:{"click":function($event){_vm.sendAllDialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"btn",attrs:{"text":"","disabled":_vm.sendingAll},on:{"click":_vm.sendAll}},[_vm._v(" Confirm ")])],1)],1)],1),_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_c(VIcon,{staticClass:"mainpageicon",attrs:{"fab":"","large":""}},[_vm._v(_vm._s(this.$route.meta.mainicon))]),_vm._v(" "+_vm._s(this.$route.meta.title.replace("Driver", _vm.driver_term))+" ")],1)]),_c('div',{staticClass:"headerbuttons"},[_c('div',{staticClass:"btn1"}),_c('div',{staticClass:"btn"},[_c(VBtn,{on:{"click":_vm.askSendall}},[_vm._v(" Send ")])],1)])]),_c(VCard,[_c(VCardTitle,[_c(VCol,{attrs:{"cols":_vm.Column}},[_c(VRow,[_c(VCheckbox,{attrs:{"id":"checkAll","name":"checkAll"},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}}),_c('label',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","vertical-align":"middle","word-wrap":"break-word"},attrs:{"for":"checkAll"}},[_vm._v("Select All "+_vm._s(_vm.driver_term))])],1)],1),_c(VRow,{staticClass:"pa-0 ma-0",attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":_vm.Column}},[_c('div',[_c(VMenu,{ref:"fmenu",attrs:{"close-on-content-click":false,"return-value":_vm.fdate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fdate=$event},"update:return-value":function($event){_vm.fdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"From","hide-details":"","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fmenu),callback:function ($$v) {_vm.fmenu=$$v},expression:"fmenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","color":"red"},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.fmenu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.fmenu.save(_vm.fdate)}}},[_vm._v(" OK ")])],1)],1)],1)]),_c(VCol,{attrs:{"cols":_vm.Column}},[_c('div',[_c(VMenu,{ref:"tmenu",attrs:{"close-on-content-click":false,"return-value":_vm.tdate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.tdate=$event},"update:return-value":function($event){_vm.tdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"hide-details":"","label":"To","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.tmenu),callback:function ($$v) {_vm.tmenu=$$v},expression:"tmenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","color":"red"},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.tmenu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.tmenu.save(_vm.tdate)}}},[_vm._v(" OK ")])],1)],1)],1)])],1)],1),_c(VDataTable,{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.data,"sort-by":"driver.id","sort-desc":true},scopedSlots:_vm._u([{key:"item.check",fn:function(ref){
              var item = ref.item;
return [_c(VCheckbox,{attrs:{"id":item.id.toString(),"name":item.id},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}},{key:"item.fullname",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.fname)+" "+_vm._s(item.lname)+" ")]}}],null,true)},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }