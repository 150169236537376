import api from "./api.js";


export default {
  data: () => ({
    show1: false,
    warning: false,
    internalError: false,
    loading: false,
    valid: true,
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    validate() {
      
      if (this.$refs.form.validate()) {
        this.loading = true;
        api
          .login(this.email, this.password)
          .then((res) => {
            this.loading = false;
            
            if (res.data.responseCode === 1) {
              var id = res.data.data.id;
              var email = res.data.data.email;
              var tenant_id = res.data.data.tenant_id;
              var is_super_admin = res.data.data.is_super_admin;
              var tenant_name = res.data.data.tenant?.name ?? "";
              var tenant_type = res.data.data.tenant?.type ?? "";

              console.log("TENANT", res.data.data.tenant?.type);

              this.$store.dispatch("login", {
                id,
                email,
                tenant_id,
                is_super_admin,
                tenant_name,
                tenant_type,
              });

              if(is_super_admin == 0){
                api.get_setting(tenant_id).then((res)=>{
                  console.log(res.data.data);
                  if(res.data.responseCode == 1){
                    res.data.data.forEach(element => {
                      localStorage.setItem(element.key, element.value);
                    });
                    this.$root.$emit('location-updated');
                  }
                })
              }else{
                this.$root.$emit('location-updated');
              }

              

              this.$router.push({
                name: "Dashboard",
              });
            } else {
              this.warning = true;
              this.loading = false;
              console.log(res.data.responseMessage);
            }
          })
          .catch((error) => {
            // this.errors.push(error);
            this.internalError = true;
            console.log(error);
            this.loading = false;
          });
      }
    },
    goBack(){
      this.$router.go(-1);
    }
  },
};
