import settings from "../../plugins/settings.js";
import api from "./api.js";

export default {

  data() {
    return {
      localSettings: settings.settings,
      locationList: [],
      spaceUnits: settings.getSpaceUnits(),
      liquidUnits: settings.getLiquidUnits(),
      currencies: settings.getCurrencyNames(),
      snackbar: false,
      text: '',
      loading: false,
    };
  },
  mounted() {
    this.getSettings();
    console.log(this.localSettings);
  },
  methods: {
    getSettings() {
      api.getSettings().then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((setting) => {
            this.$set(this.localSettings, setting.key, setting.value);
          });
        }
      });
    },

    saveSettings() {
      this.loading = true;

      const settingsArray = Object.entries(this.localSettings).map(([key, value]) => ({
        key: key,
        value: value,
      }));

      

      api
        .save_all_settings(settingsArray)
        .then((res) => {
          if (res.data.responseCode === 1) {
            this.text = "Settings updated successfully";
          } else {
            this.text = "Failed to update settings";
          }
          this.snackbar = true;
        })
        .catch((error) => {
          this.text = "An error occurred while updating settings";
          console.error(error);
          this.snackbar = true;
        })
        .finally(() => {
          this.loading = false;
          settings.setSettings(settingsArray);
        });
    },

    resetSettings() {
      this.getSettings();
    },
    onPlaceSelected(place){
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.localSettings.lat = latitude + "";
      this.localSettings.lon = longitude + "";
      this.localSettings.location = place.formatted_address;
    }
  },
};