import axios from "axios";

//
let API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
let config = {
    baseURL: `${API_ENDPOINT}`,
    withCredentials: true, // Check cross-site Access-Control
};

const httpClient = axios.create(config);

httpClient.interceptors.response.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);

export default httpClient;
