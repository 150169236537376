import api from "./api.js"

var driver_term = localStorage.getItem("driver_term") || "Driver";
var trip_term = localStorage.getItem("trip_term") || "Trip";

export default {
  data: () => ({
    selected_exception_id: null,
    selected_exception: null,
    interval: null,
    json_fields: {
      'Id': 'id',
      [`${driver_term} Name`]: 'driver_name',
      [`${trip_term} Segment`]: 'trip_origin',
      'Exception Type': 'exception_type',
      'Exception Time': 'exception_time',
    },
    exception_type: new Map([
      ["started_late", [ trip_term +" started late", "mdi-clock-alert"]],
      ["started_soon", [ trip_term + "started soon", "mdi-clock-alert"]],
    ]),
    NormalRules: [
      v => !!v || 'Field is required',
    ],
    data: [],
    driver_term: driver_term,
    trip_term: trip_term,
    headers: [
      {
        text: driver_term + ' Name',
        align: 'center',
        value: 'driver_name',
      },
      {
        text: trip_term + ' Origin',
        align: 'center',
        value: 'trip_origin',
      },
      {
        text: 'Exception Type',
        align: 'center',
        value: 'exception_type',
      },
      {
        text: 'Exception Time',
        align: 'center',
        value: 'exception_time',
      },
      {
        text: 'Action',
        align: 'center',
        value: 'actions'
      }
    ],
    infoDialog: false,
    loading: false,
    search: '',
    error: null,
    errorDialog: false,
    myCurrentData: [],
  }),
  mounted() {
   
    this.fetch();
    this.interval = setInterval(this.checkSessionStorage, 1000);
  },
  beforeDestroyed(){
    clearInterval(this.interval);
  },
  destroyed(){
    sessionStorage.removeItem("cooriroo_exception_id")
  },
  methods: {
    checkSessionStorage() {
      const value = sessionStorage.getItem("cooriroo_exception_id");
      if (value !== (this.selected_exception_id !== null ? this.selected_exception_id.toString() : null)) {
          this.selected_exception_id = value !== null ? parseInt(value) : null;
      }
  },
   

    fetch() {
      this.loading = true;
      api.getExceptions()
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            res.data.data.forEach((element) => {
              this.data.push({
                id: element.id,
                driver_name: `${element.driver.fname} ${element.driver.lname}`,
                trip_origin: element.trip.warehouse.title,
                type: element.type,
                original_start_date: new Date(element.trip.start_date).toLocaleString(),
                exception_type: this.exception_type.get(element.type)?.[0] || element.type,
                exception_time: new Date(element.creation_date).toLocaleString(),
                original_start_trip: element.start_date,
                data: element.data,
                destinations: element.trip.destinations.map(({ destination_name, task }) => ({
                  destination_name,
                  task: task || null
                }))
              });
              
            });
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getRowClass(item) {
      return item.id === this.selected_exception_id ? 'highlight-row' : '';
    },

    getFiltered(e) {
      this.myCurrentData = e;
      // Output the filtered items
    },

    showInfo(item) {
      this.infoDialog = true;
      this.selected_exception = item;
    }
    
  },
};
