import api from './api.js';
export default {
    data: () => ({
        valid: false,
        dialog: false,
        selectedObject: { id: -1 },
        loading: true,
        deleteDialog: false,
        title: null,
        search: '',
        id: null,
        clicked: false,
        myCurrentData: [],
        fdate: null,
        tdate: null,
        fmenu: false,
        json_fields: {
            'Id': 'id',
            'File': 'file',
            'Line': 'line',
            'Error': 'error',
            'User Id': 'user_id',
            'User Type': 'user_type',
            'Extra': 'extra',
        },

        NormalRules: [
            v => !!v || 'Field is required',

        ],
        data: [],
        headers: [
        {
            text: 'File',
            align: 'center',
            value: 'file',
        },
        {
            text: 'Line',
            align: 'center',
            value: 'line'
        },
        {
            text: 'Error',
            align: 'center',
            value: 'error'
        },
        {
            text: 'User Id',
            align: 'center',
            value: 'user_id'
        },
        {
            text: 'User Type',
            align: 'center',
            value: 'user_type'
        }, {
            text: 'Extra',
            align: 'center',
            value: 'extra'
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'center',
        },
        ],

    }),
    mounted() {
        var myCurrentDate = new Date();

        this.fdate = (myCurrentDate.toISOString().substr(0, 10))
        this.fetch();

    },
    methods: {
        fetch() {
            this.loading = true;
            this.data = [];
            api.getLogs(this.fdate).then((res) => {
                this.loading = false;
                if (res.data.responseCode === 1) {
                    this.data = res.data.data;
                }
            }).catch((error) => {
                this.error = error;
                this.loading = false;
                this.errorDialog = true;
            });
        },
        getFiltered(e) {
            this.myCurrentData = e;
            //output the filtered items
        },
        onDelete(item) {
            this.selectedObject = item;
            this.deleteDialog = true;
        },

        agreeOnDelete() {
            this.clicked = true;
            this.loading = true;
            api.archive(this.selectedObject.id).then((res) => {
                this.loading = false;
                if (res.data.responseCode === 1) {

                    this.deleteDialog = false;
                    this.clicked = false;
                    var index = this.data.indexOf(this.selectedObject);
                    this.$delete(this.data, index);
                    this.text = 'Driver deleted succesfully.';
                    this.snackbar = true;
                } else {
                    console.log(res.data.responseMessage);
                }
            }).catch((error) => {
                this.loading = false;
                this.text = error;
                this.snackbar = true;
                this.deleteDialog = false;
                this.clicked = false;
            });
        },




    },
}