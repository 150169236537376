import axios from "@/plugins/http.service.js";

export default {
  getdrivers(fdate, tdate) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
    };
    return axios.post(
      `driver/get-drivers-working-hours?tenant_id=${tenant_id}`,
      data
    );
  },
};
