import axios from "@/plugins/http.service.js"

export default {
    login(email, pass) {
        var data = {
            'email': email,
            'password': pass
        };

        return axios.post("admin/login", data);
    },

    get_setting(id) {
        const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
        return axios.get(
          `tenant-settings/get-settings?tenant_id=${id ? id : tenant_id}`
        );
      },
};