import axios from "@/plugins/http.service.js";

export default {
  sendAllDriversSummary(data) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    const temp = { ...data, tenant_id };
    return axios.post("driver/send-all-driver-finance-summary", temp);
  },
  getdrivers() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`driver/get-drivers?tenant_id=${tenant_id}`);
  },
  getFinance() {
    return axios.post("settings/finance-email");
  },
};
