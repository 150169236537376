import router from "../router/index.js";
import api from "./api.js";
import axios from "@/plugins/http.service.js";
import settings from "../plugins/settings.js";

var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);

var driver_term = localStorage.getItem("driver_term") || "Driver";
var trip_term = localStorage.getItem("trip_term") || "Trip";
var destination_term = localStorage.getItem("destination_term") || "Trip";

export default {
  name: "mainlayout",
  data() {
    return {
      email: sessionStorage.getItem("cooriroo_admin_email"),
      tenant_name: sessionStorage.getItem("tenant_name") ?? null,
      tenant_type: sessionStorage.getItem("tenant_type") ?? null,
      exception_type: new Map([
        ["started_late", [trip_term + " started late", "mdi-clock-alert"]],
        ["started_soon", [trip_term + " started soon", "mdi-clock-alert"]],
      ]),
      drawer: true,
      mini: true,
      toright: false,
      mobileView: false,
      dropDownMenu: false,
      exceptions: [],
      notSeenExceptionsCounter: 0,
      intervalId: null,
      fullname: "Admin",
      loggedin: false,
      limit: 4, // limit seen exceptions
      items: [],
      firststyle: "color:red",
      secondstyle: "transform: scaleX(-1);filter: FlipH; ;",
      minWidth: 80,
      currency: currency,
      trip_term: trip_term,
      driver_term: driver_term,
      destination_term: destination_term,
    };
  },
  mounted() {
    this.onResize(null);
    this.loggedin = true;

    this.items = [
      {
        title: "Dashboard",
        icon: "dashboard",
        to: "/dashboard",
      },
      {
        title: "Vehicles",
        icon: "directions_bus",
        to: "/vehicles",
      },
      {
        title: this.tenant_type === "namat" ? "Staff" : driver_term + "s",
        icon: "person_search",
        to: "/drivers",
      },
      {
        title: "Origins",
        icon: "apartment",
        to: "/origins",
      },
      {
        title: destination_term,
        icon: "place",
        to: "/destinations",
      },
      {
        title: this.tenant_type === "namat" ? "Tasks" : trip_term + "s",
        icon: "loyalty",
        to: "/trips",
      },
      {
        title:
          this.tenant_type === "namat"
            ? "Scheduled Tasks"
            : "Scheduled " + trip_term + "s",
        icon: "timer",
        to: "/scheduled-trips",
      },
      {
        title:
          this.tenant_type === "namat" ? "Task types" : trip_term + " Types",
        icon: "format_list_bulleted",
        to: "/trip-types",
      },
      {
        title: "Refuel",
        icon: "local_gas_station",
        to: "/refuel",
      },
      {
        title: "Accidents",
        icon: "bus_alert",
        to: "/accidents",
      },
      {
        title: "Fines",
        icon: "request_quote",
        to: "/fines",
      },
      {
        title: "Reports",
        icon: "insert_chart",
        children: [
          // {
          //   title: "Map",
          //   icon: "map",
          //   to: "/map",
          // },
          {
            title:
              this.tenant_type === "namat"
                ? "Staff summary"
                : driver_term + " Summary",
            icon: "description",
            to: "/driver-summary",
          },
          {
            title: "Finance Summary",
            icon: currency,
            to: "/finance-summary",
          },
          {
            title: "Logs",
            icon: "source",
            to: "/logs",
          },
        ],
      },
      {
        title: "Requests",
        icon: "sync_alt",
        to: "/change-vehicle-requests",
      },
      {
        title: "Exceptions",
        icon: "subject",
        to: "/exceptions",
      },
      {
        title: "Admins",
        icon: "admin_panel_settings",
        to: "/admins",
      },

      {
        title: "Push Notifications",
        icon: "notifications_none",
        to: "/push-notifications",
      },
    ];
    if (this.tenant_type === "namat") {
      this.items = [
        {
          title: "Dashboard",
          icon: "dashboard",
          to: "/dashboard",
        },
        {
          title: "Messages",
          icon: "message",
          to: "/push-notifications",
        },
        {
          title: "Resources",
          icon: "now_widgets",
          to: "/vehicles",
        },
        {
          title: this.tenant_type === "namat" ? "Staff" : driver_term + "s",
          icon: "person_search",
          to: "/drivers",
        },
        {
          title: "Locations",
          icon: "apartment",
          to: "/origins",
        },
        {
          title: this.tenant_type === "namat" ? "Tasks" : trip_term + "s",
          icon: "check_circle_outline",
          to: "/trips",
        },
        {
          title:
            this.tenant_type === "namat"
              ? "Scheduled Tasks"
              : "Scheduled " + trip_term + "s",
          icon: "timer",
          to: "/scheduled-trips",
        },
        {
          title:
            this.tenant_type === "namat" ? "Task types" : trip_term + " Types",
          icon: "format_list_bulleted",
          to: "/trip-types",
        },
        {
          title: "Emergency events",
          icon: "alarm",
          to: "/accidents",
        },
        {
          title: "Reports",
          icon: "insert_chart",
          children: [
            {
              title:
                this.tenant_type === "namat"
                  ? "Performance summary"
                  : driver_term + " Summary",
              icon: "description",
              to: "/driver-summary",
            },
            {
              title: "Logs",
              icon: "source",
              to: "/logs",
            },
            {
              title: "Analytics",
              icon: "insert_chart",
              to: "/analytics",
            },
          ],
        },
        {
          title: "Requests",
          icon: "sync_alt",
          to: "/change-vehicle-requests",
        },
        {
          title: "Exceptions",
          icon: "subject",
          to: "/exceptions",
        },
        {
          title: "Admins",
          icon: "admin_panel_settings",
          to: "/admins",
        },
      ];
    }

    if (sessionStorage.getItem("is_super_admin") == 1) {
      this.items.push({
        title: "Super Admins",
        icon: "admin_panel_settings",
        to: "/super-admins",
      });
    }

    this.intervalId = setInterval(() => {
      this.getExceptions();
    }, 10000);
  },
  created() {
    document.addEventListener("resize", this.onResize);
  },
  destroyed() {
    document.removeEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  computed: {
    tenantName: function () {
      if (this.tenant_type === "namat") {
        return "";
      }
      return this.tenant_name
        ? "COORIROO ADMINISTRATION - " + this.tenant_name
        : "";
    },
    logo: function () {
      var images = require.context("../assets/images", false);
      if (this.tenant_type === "namat") {
        return images("./namat.png");
      }
      return images("./logo.svg");
    },
    barStyle: function () {
      if (this.tenant_type === "namat") {
        return "border-bottom: 2px solid #ec4962; background: white";
      }
      return "border-bottom: 2px solid #fbc853; background: white";
    },
  },

  methods: {
    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },
    parseDate(dateString) {
      const [datePart, timePart] = dateString.split(", ");
      const [day, month, year] = datePart.split("/").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);

      return new Date(year, month - 1, day, hours, minutes, seconds);
    },
    parseAndConvert(dateString) {
      return this.convertUTCDateToLocalDate(this.parseDate(dateString));
    },
    changeBar() {
      if (this.mini != false) {
        this.mini = !this.mini;
      }
    },
    getExceptions() {
      api
        .getExceptions()
        .then((response) => {
          if (response.data.success) {
            const newExceptions = response.data.data.map((exception) => ({
              id: exception.id,
              text:
                this.exception_type.get(exception.type)?.[0] || exception.type,
              driver_name: exception.driver.fname,
              driver_surname: exception.driver.lname,
              departed_from: exception.trip.warehouse.title,
              time_exception: this.convertUTCDateToLocalDate(
                exception.creation_date
              ),
              seen: exception.seen,
              icon: this.exception_type.get(exception.type)?.[1] || null,
            }));

            const existingExceptionsMap = new Map(
              this.exceptions.map((exception) => [exception.id, exception])
            );

            newExceptions.forEach((newException) => {
              const existingException = existingExceptionsMap.get(
                newException.id
              );
              if (existingException) {
                if (existingException.seen !== newException.seen) {
                  existingException.seen = newException.seen;
                }
                existingException.time_exception = newException.time_exception;
              } else {
                this.exceptions.push(newException);
              }
            });

            // Separate unseen and seen exceptions to limit the seen ones
            const unseenExceptions = this.exceptions.filter((n) => !n.seen);
            const seenExceptions = this.exceptions.filter((n) => n.seen);

            const limitedSeenExceptions = seenExceptions.slice(0, this.limit);

            this.exceptions = unseenExceptions.concat(limitedSeenExceptions);
            this.exceptions.sort((a, b) => {
              if (a.seen === b.seen) {
                return new Date(b.time_exception) - new Date(a.time_exception);
              }
              return a.seen - b.seen;
            });

            this.notSeenExceptionsCounter = unseenExceptions.length;
            if (this.notSeenExceptionsCounter > 0) {
              this.setNewIcon();
            } else {
              this.resetIcon();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setNewIcon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = "/favicon_notification.svg";
    },
    resetIcon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = "/favicon.svg";
    },

    markAllAsRead() {
      this.resetIcon();
      this.notSeenExceptionsCounter = 0;
      this.exceptions.forEach((exception) => {
        exception.seen = true;
      });
      this.dropDownMenu = false;
      api.readAllException();
    },

    redirect(exceptionId) {
      if (sessionStorage.getItem("cooriroo_admin_id") !== null) {
        sessionStorage.setItem("cooriroo_exception_id", exceptionId);

        if (router.currentRoute.name !== "Exceptions") {
          router.push("/exceptions");
        }

        api.readException(exceptionId);

        const exception = this.exceptions.find((x) => x.id === exceptionId);
        if (exception) {
          if (!exception.seen) {
            exception.seen = true;

            this.notSeenExceptionsCounter--;

            if (this.notSeenExceptionsCounter === 0) {
              this.resetIcon();
            }
          }
        }

        this.dropDownMenu = false;
      } else {
        router.push("/");
      }
    },

    logout: function () {
      sessionStorage.removeItem("cooriroo_admin_id");
      sessionStorage.removeItem("cooriroo_tenant_id");
      sessionStorage.removeItem("cooriroo_admin_email");
      sessionStorage.removeItem("is_super_admin");
      sessionStorage.removeItem("tenant_name");
      sessionStorage.removeItem("tenant_type");
      sessionStorage.removeItem("cooriroo_exception_id");

      localStorage.clear();

      this.$router.push("/");

      axios
        .post("admin/logout")
        .then(() => {
          console.log("Logout successfull.");
        })
        .catch((error) => {
          console.error("error during logout:", error);
        });
    },

    onResize() {
      this.minWidth = "80";
      if (window.innerWidth >= 1024) {
        this.mobileView = false;
      } else {
        this.mobileView = true;
      }
    },
  },
};
