export default{
    defaultTenantSettings: [ "currency",  "location", "measure_unit_space", "measure_unit_liquid", "driver_term",  "trip_term", "destination_term", "lat", "lon"],
    settings: {
        currency: "United Arab Emirates Dirham",
        location: "Dubai",
        space_unit: "Kilometer",
        liquid_unit: "Liter",
        driver_term: "Driver",
        trip_term: "Trip",
        destination_term: "Destination",
        lat: "25.276987",
        lon: "55.296249",

      },
    spaceUnits: [
        { name: "Kilometer", symbol: "km" },
        { name: "Mile", symbol: "mi" },
      ],
      liquidUnits :[
        { name: "Liter", symbol: "L" },
        { name: "Cubic Meter", symbol: "m³" },
        { name: "Cubic Centimeter", symbol: "cm³" },
        { name: "Cubic Inch", symbol: "in³" },
        { name: "Cubic Foot", symbol: "ft³" },
        { name: "Gallon (US)", symbol: "gal" },
        { name: "Gallon (UK)", symbol: "gal (UK)" },
      ],
      
      currencies: [
        {"code": "AED", "name": "United Arab Emirates Dirham", "symbol": "د.إ"},
        {"code": "AFN", "name": "Afghan Afghani", "symbol": "؋"},
        {"code": "ALL", "name": "Albanian Lek", "symbol": "L"},
        {"code": "AMD", "name": "Armenian Dram", "symbol": "֏"},
        {"code": "ARS", "name": "Argentine Peso", "symbol": "$"},
        {"code": "AUD", "name": "Australian Dollar", "symbol": "$"},
        {"code": "BGN", "name": "Bulgarian Lev", "symbol": "лв"},
        {"code": "BRL", "name": "Brazilian Real", "symbol": "R$"},
        {"code": "CAD", "name": "Canadian Dollar", "symbol": "$"},
        {"code": "CHF", "name": "Swiss Franc", "symbol": "CHF"},
        {"code": "CNY", "name": "Chinese Yuan", "symbol": "¥"},
        {"code": "COP", "name": "Colombian Peso", "symbol": "$"},
        {"code": "CZK", "name": "Czech Republic Koruna", "symbol": "Kč"},
        {"code": "DKK", "name": "Danish Krone", "symbol": "kr"},
        {"code": "EGP", "name": "Egyptian Pound", "symbol": "£"},
        {"code": "EUR", "name": "Euro", "symbol": "€"},
        {"code": "GBP", "name": "British Pound Sterling", "symbol": "£"},
        {"code": "HKD", "name": "Hong Kong Dollar", "symbol": "$"},
        {"code": "HUF", "name": "Hungarian Forint", "symbol": "Ft"},
        {"code": "IDR", "name": "Indonesian Rupiah", "symbol": "Rp"},
        {"code": "ILS", "name": "Israeli New Sheqel", "symbol": "₪"},
        {"code": "INR", "name": "Indian Rupee", "symbol": "₹"},
        {"code": "JPY", "name": "Japanese Yen", "symbol": "¥"},
        {"code": "KRW", "name": "South Korean Won", "symbol": "₩"},
        {"code": "MXN", "name": "Mexican Peso", "symbol": "$"},
        {"code": "MYR", "name": "Malaysian Ringgit", "symbol": "RM"},
        {"code": "NOK", "name": "Norwegian Krone", "symbol": "kr"},
        {"code": "NZD", "name": "New Zealand Dollar", "symbol": "$"},
        {"code": "PHP", "name": "Philippine Peso", "symbol": "₱"},
        {"code": "PLN", "name": "Polish Zloty", "symbol": "zł"},
        {"code": "RON", "name": "Romanian Leu", "symbol": "lei"},
        {"code": "RUB", "name": "Russian Ruble", "symbol": "₽"},
        {"code": "SAR", "name": "Saudi Riyal", "symbol": "﷼"},
        {"code": "SEK", "name": "Swedish Krona", "symbol": "kr"},
        {"code": "SGD", "name": "Singapore Dollar", "symbol": "$"},
        {"code": "THB", "name": "Thai Baht", "symbol": "฿"},
        {"code": "TRY", "name": "Turkish Lira", "symbol": "₺"},
        {"code": "UAH", "name": "Ukrainian Hryvnia", "symbol": "₴"},
        {"code": "USD", "name": "US Dollar", "symbol": "$"},
        {"code": "VND", "name": "Vietnamese Dong", "symbol": "₫"},
        {"code": "ZAR", "name": "South African Rand", "symbol": "R"}
    ],
      

  setTerm(term, field){
    if(!(field in this.defaultTenantSettings)) return false;
    localStorage.setItem(field, term);
    return true;
  },

  getCurrencyNames(){
    return this.currencies.map(currency => currency.name);
  },

  getLiquidUnits(){
    return this.liquidUnits.map(unit => unit.name);
  },

  getSpaceUnits(){
    return this.spaceUnits.map(unit => unit.name);
  },

  setSettings(settings){
    settings.forEach((setting) =>{
        localStorage.setItem(setting.key, setting.value);
    })
    window.location.reload();
  },

  fromNameToSymbol(name, type = "currency") {
    let selectedItem = null;

    switch (type) {
      case "currency":
        selectedItem = this.currencies.find((curr) => curr.name === name);
        break;
      case "spaceUnit":
        selectedItem = this.spaceUnits.find((unit) => unit.name === name);
        break;
      case "liquidUnit":
        selectedItem = this.liquidUnits.find((unit) => unit.name === name);
        break;
    }

    if (selectedItem) {
      return selectedItem.symbol;
    } else {
      console.error(`${type} with name "${name}" not found.`);
      return null;
    }
  },
  

  

  
  
}