<template>
  <div class="wrapper">
    <!-- Demand vs Capacity Bar Chart -->
    <v-card style="max-height: 440px; min-height: 440px; padding: 20px">
      <v-card-title>Demand vs Capacity</v-card-title>
      <BarChart
        id="my-chart-id"
        :options="chartOptions"
        :data="chartData"
        style="max-height: 336px"
      />
    </v-card>

    <!-- Job Accomplishments Line Chart -->
    <v-card style="max-height: 440px; min-height: 440px; padding: 20px">
      <v-card-title>Job Accomplishments Today</v-card-title>
      <LineChart
        id="my-line-id"
        :options="chartOptions"
        :data="lineChartData"
        style="max-height: 336px"
      />
    </v-card>

    <!-- Pending Tasks Table -->
    <v-card>
      <v-card-title>Pending Tasks</v-card-title>
      <v-data-table
        :headers="headers"
        :items="pendingTasks"
        :search="search"
        sort-by="id"
        :sort-desc="false"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="red" @click="showTaskDetails(item)">perm_media</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { Bar, Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  BarElement,
  LinearScale,
  CategoryScale
);

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { BarChart: Bar, LineChart: Line },

  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Work Order", value: "workOrder" },
        { text: "Description", value: "description" },
        { text: "Priority", value: "priority" },
        { text: "Assignee", value: "assignee" },
      ],
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Demand",
            data: [40, 20, 12, 22, 33, 24, 54, 11, 65, 33, 22, 22],
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgb(255, 99, 132)"],
          },
          {
            label: "Capacity",
            data: [32, 11, 33, 22, 22, 33, 44, 23, 35, 32, 55],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192 )"],
          },
        ],
      },
      lineChartData: {
        labels: [
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
        ],
        datasets: [
          {
            label: "Jobs Accomplished",
            data: [40, 20, 12, 22, 33, 24, 54, 11, 65, 33, 22, 22],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
          {
            label: "Jobs Pending",
            data: [32, 11, 33, 22, 22, 33, 44, 23, 35, 32, 55],
            fill: false,
            borderColor: "rgb(255, 99, 132)",
            tension: 0.1,
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
      lineChartOptions: {
        responsive: true,
        scales: {
          x: {
            type: "category",
          },
          y: {
            type: "linear",
          },
        },
      },
      pendingTasks: [
        {
          id: 1,
          workOrder: "WO-001",
          description: "Fix runway lights",
          priority: "High",
          assignee: "John Doe",
        },
        {
          id: 2,
          workOrder: "WO-002",
          description: "Repair baggage carousel",
          priority: "Medium",
          assignee: "Jane Smith",
        },
        {
          id: 3,
          workOrder: "WO-003",
          description: "Inspect fuel lines",
          priority: "High",
          assignee: "Mike Johnson",
        },
        {
          id: 4,
          workOrder: "WO-004",
          description: "Replace air conditioning filters",
          priority: "Low",
          assignee: "Anna Lee",
        },
        {
          id: 5,
          workOrder: "WO-005",
          description: "Check emergency exits",
          priority: "High",
          assignee: "Chris Brown",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
