import Vue from "vue";
import Vuetify from "vuetify/lib";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "md", // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        black: "#333333",
        primary:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "#ec4962"
            : "#f44336",
        secondary: "#b0bec5",
        red:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "#ec4962"
            : "#f44336",
        accent: "#8c9eff",
        error: "#b71c1c",
        green:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "#ec4962"
            : "#f44336",
        green1: "#5EBC01",
        orange:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "#ec4962"
            : "#FBC853",
      },
    },
  },
});

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "md",
});

export default vuetify;
