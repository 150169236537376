import api from "./api.js";
import settings from "../../plugins/settings.js";
var driver_term = localStorage.getItem("driver_term") || "Driver";
var currency = settings.fromNameToSymbol(localStorage.getItem("currency") || "Euro");
export default {
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    errormessages: null,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    addBusDialog: false,
    snackbarMessage: null,
    showBusCombobox: true,
    searchTermBus: '',
    newBus: {
      reference: null
    },
    search: "",
    id: null,
    clicked: false,
    isrequired: true,
    selected_bus: null,
    driver_term: driver_term,
    currency: currency,
    json_fields: {
      Id: "id",
      "First Name": "fname",
      "Last Name": "lname",
      "E-mail": "email",
      "Vehicle Reference": "bus.reference",
      "Created Date": "created_date",
      "Hourly Rate": "hourly_rate",
    },
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
      (v) => (v && v.length >= 4) || "Minimum 4 Characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    NormalRules: [(v) => !!v || "Field is required"],
    noRules: [],
    snackbar: false,
    text: "",
    hourly_rate: 0.0,
    busesCount: null,
    show1: false,
    show2: false,
    email: null,
    fname: null,
    lname: null,
    buses: [],
    language: "",
    languages: [
      "Afrikaans",
      "Arabic",
      "Armenian",
      "Azerbaijani",
      "Belarusian",
      "Bosnian",
      "Bulgarian",
      "Catalan",
      "Chinese",
      "Croatian",
      "Czech",
      "Danish",
      "Dutch",
      "English",
      "Estonian",
      "Finnish",
      "French",
      "Galician",
      "German",
      "Greek",
      "Hebrew",
      "Hindi",
      "Hungarian",
      "Icelandic",
      "Indonesian",
      "Italian",
      "Japanese",
      "Kannada",
      "Kazakh",
      "Korean",
      "Latvian",
      "Lithuanian",
      "Macedonian",
      "Malay",
      "Marathi",
      "Maori",
      "Nepali",
      "Norwegian",
      "Persian",
      "Polish",
      "Portuguese",
      "Romanian",
      "Russian",
      "Serbian",
      "Slovak",
      "Slovenian",
      "Spanish",
      "Swahili",
      "Swedish",
      "Tagalog",
      "Tamil",
      "Thai",
      "Turkish",
      "Ukrainian",
      "Urdu",
      "Vietnamese",
      "Welsh",
    ],
    myCurrentData: [],
    p1: null,
    p2: null,
    ddata: [],
    headers: [
      {
        text: "First Name",
        align: "center",
        value: "fname",
      },
      {
        text: "Last Name",
        align: "center",
        value: "lname",
      },
      {
        text: "E-mail",
        value: "email",
        align: "center",
      },
      {
        text: "Vehicle Reference",
        value: "bus",
        align: "center",
      },
      {
        text: "Creation Date",
        value: "created_date",
        align: "center",
        filterable: false,
      },
      {
        text: "Hourly Rate",
        value: "hourly_rate",
        align: "center",
        filterable: false,
      },

      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    if ((sessionStorage.getItem("tenant_type") ?? null) === "namat") {
      this.headers = [
        {
          text: "First Name",
          align: "center",
          value: "fname",
        },
        {
          text: "Last Name",
          align: "center",
          value: "lname",
        },
        {
          text: "E-mail",
          value: "email",
          align: "center",
        },
        {
          text: "Department",
          value: "department",
          align: "center",
        },
        {
          text: "Role",
          value: "role",
          align: "center",
        },
        {
          text: "Skills",
          value: "skills",
          align: "center",
        },
        {
          text: "Vehicle Reference",
          value: "bus",
          align: "center",
        },
        {
          text: "Creation Date",
          value: "created_date",
          align: "center",
          filterable: false,
        },
        {
          text: "Hourly Rate",
          value: "hourly_rate",
          align: "center",
          filterable: false,
        },

        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ];
    }

    api
      .getdrivers()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            this.ddata.push(element);
          });
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
    this.refreshbuses();
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.handleKeyPress);
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.p1 === this.p2 || "Password must match";
    },
    filteredBuses() {
      const searchTermBus = this.searchTermBus ? this.searchTermBus.toLowerCase() : '';  // Ensure searchTermBus is a string
      const buses = this.buses.filter(bus => bus?.reference?.toLowerCase().includes(searchTermBus)); // Add null checks
  
      if (searchTermBus && !buses.length) {
        return [...buses, { id: -1, title: `Create a new vehicle` }];
      }
      return buses;
    },
  },
  watch: {
    p1: {
      handler(value) {
        
        if (value && value.toString().length > 0) {
          this.isrequired = true;
        } else {
          this.isrequired = false;
        }
      },
    },
    p2: {
      handler(value) {
        if (value && value.toString().length > 0) {
          this.isrequired = true;
        } else {
          this.isrequired = false;
        }
      },
    },
  },

  methods: {
    handleBusChange(selected) {
      if (selected && selected.id === -1) {
        this.selected_bus = { reference: null }; 
        this.showAddBusDialog();
      }
    },
    customBusFilter(item, queryText) {
      if (item.id === -1) return true;
      return item.reference.toLowerCase().includes(queryText.toLowerCase());
    },
    closeAddBusDialog() {
      this.showBusCombobox = false;
      this.selected_bus = { reference: null }; 
      this.$nextTick(() => {
        this.showBusCombobox = true;
      });
      this.addBusDialog = false;
    },

    showAddBusDialog(){
      this.addBusDialog = true;
      this.newBus.reference = null;
    },

    saveBus() {
      if (!this.$refs.bus_form.validate()){
        this.snackbarMessage = "Please fill the form correctly";
         
        this.snackbarColor = "error";
        this.snackbar = true;
        return; 
      }
      api
        .addbus(this.newBus.reference)
        .then((res) => {
          if (res.data.responseCode === 1) {
            this.selected_bus = {
              id: res.data.data.id,
              reference: this.newBus.reference,
              statusText: "No Assigned",
              driverFullName: "N/A",
              drivers: [],
            };
            api
              .getbuses()
              .then((res) => {
                if (res.data.responseCode === 1) {
                  this.buses = res.data.data;
                  this.busesCount = res.data.data.length;
                  if (this.busesCount < this.buses.length) this.buses.pop();
                }
              })
              .catch((error) => {
                this.snackbarMessage = "Error fetching buses: " + error;
                this.snackbarColor = "error";
                this.snackbar = true;
              });
            this.snackbarMessage = "Vehicle added successfully.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.addBusDialog = false;
          } else {
            this.snackbarMessage = res.data.responseMessage || "Failed to add vehicle.";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.snackbarMessage = "Error adding vehicle: " + error;
          this.snackbarColor = "error";
          this.snackbar = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleKeyPress(event) {
      if (event.key === "Enter") {
        switch (true) {
          case this.addBusDialog:
            this.saveBus();
            return;
          case this.dialog:
            this.save();
            return;
          case this.deleteDialog:
            this.agreeOnDelete();
            return;
          default:
            break;
        }
      }
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    refreshbuses() {
      this.buses = [];
      api
        .getbuses()
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.buses = res.data.data;
            this.busesCount = res.data.data.length;
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      if (this.busesCount < this.buses.length) this.buses.pop();
      this.valid = true;
      this.p1 = null;
      this.p2 = null;
      if (item == null) {
        this.isrequired = true;
        this.selectedObject = { id: -1 };
        this.fname = null;
        this.lname = null;
        this.email = null;
        this.id = null;
        this.selected_bus = null;
        this.hourly_rate = 0.0;
      } else {
        this.selectedObject = item;
        this.isrequired = false;
        this.fname = item.fname;
        this.lname = item.lname;
        this.hourly_rate = item.hourly_rate;
        this.email = item.email;
        this.language = item.language;

        if (item.bus != null) {
          this.buses.push({
            id: parseInt(item.bus.id),
            reference: item.bus.reference,
          });
          this.selected_bus = {
            id: parseInt(item.bus.id),
            reference: item.bus.reference,
          };
        } else {
          this.selected_bus = null;
        }
        this.id = item.id;
      }
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.ddata.indexOf(this.selectedObject);
            this.$delete(this.ddata, index);
            this.snackbarMessage = driver_term + " deleted succesfully.";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    save() {
      this.clicked = true;
      console.log("asd");
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return;
      }
      if (this.isrequired == true && this.p1 != this.p2) {
        this.snackbarMessage = "Password does not match.";
        this.snackbar = true;
        this.clicked = false;
        return;
      }
      this.loading = true;
      let OldBusId = null;
      let busId = -1;
      if (this.selected_bus != null) {
        busId = this.selected_bus.id;
      }
      if (this.selectedObject.bus != null) {
        OldBusId = this.selectedObject.bus.id;
      }

      if (this.selectedObject.id != -1) {
        this.clicked = true;
        api
          .save(
            this.id,
            this.fname,
            this.lname,
            this.email,
            this.p1 == null ? null : this.p1,
            OldBusId,
            busId,
            parseFloat(this.hourly_rate).toFixed(2),
            this.language
          )
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.ddata.indexOf(this.selectedObject);
              this.ddata[index].fname = this.fname;
              this.ddata[index].lname = this.lname;
              this.ddata[index].email = this.email;
              this.ddata[index].hourly_rate = this.hourly_rate;
              this.ddata[index].bus = this.selected_bus;

              this.snackbarMessage = driver_term + " updated successfully.";
              this.snackbar = true;
              this.refreshbuses();
            } else {
              this.snackbarMessage = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarMessage = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;

            this.clicked = false;
          });
      } else {
        this.clicked = true;
        api
          .add(
            this.fname,
            this.lname,
            this.email,
            this.p1,
            this.selected_bus == null ? null : this.selected_bus.id,
            parseFloat(this.hourly_rate).toFixed(2),
            this.language
          )
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.ddata.push({
                id: res.data.data.id,
                fname: this.fname,
                lname: this.lname,
                email: this.email,
                hourly_rate: this.hourly_rate,
                created_date: res.data.data.created_date,

                bus:
                  busId == -1
                    ? null
                    : {
                        id: this.selected_bus.id,
                        reference: this.selected_bus.reference,
                      },
              });

              this.snackbarMessage = driver_term + " added successfully.";
              this.snackbar = true;
              this.refreshbuses();
            } else {
              this.snackbarMessage = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarMessage = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;

            this.clicked = false;
          });
      }
    },
  },
};
