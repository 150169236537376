import axios from "@/plugins/http.service.js";

export default {
  gettrips(fdate, tdate, driver_id) {
    var data = {
      fdate: fdate,
      tdate: tdate,
      driver_id: driver_id,
    };
    return axios.post("trip/get-driver-all-trips", data);
  },
};
