import api from "./api.js";

export default {
  data() {
    return {
      content: {},
      sections: ['hero_section', 'detail_section', 'component_section','benefit_section', 'feature_section', 'demo_section', 'contact_section' ],
      contentError: false,
      loading: false,
      text: null,
      errorText: null,
      fullName: null,
      email: null,
      changeBackgroundColor: false,
      phoneNumber: null,
      companySize: null,
      discuss: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: [(v) => !!v || "Field is required"],
      drawer: false,
      webStyle:
        "text-align: center !important;font-size: 54px !important;color: #333333 !important;font-weight: bold !important;",
      phoneStyle:
        "text-align: center !important;font-size: 32px !important;color: #333333 !important;font-weight: bold !important;",
      webStyle1:
        "text-align: left !important;font-size: 54px !important;color: #333333 !important;font-weight: bold !important;",
      phoneStyle1:
        "text-align: left !important;font-size: 32px !important;color: #333333 !important;font-weight: bold !important;",
    };
  },
  mounted() {},
  beforeMount(){
    api.getContent().then((res) => {
      var data = res.data.data;
      this.sections.forEach((section) => {
        this.content[section] = data[section];
      });
      this.$forceUpdate(); 
    }).catch(() => {
      //TODO: add contentError panel
      this.contentError = true;
    });
  },
  methods: {
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while (obj === obj.offsetParent);
        return [curtop];
      }
    },
    scroll(destination, demo = false) {
      if (this.drawer == true) this.drawer = false;
      window.scroll(0, this.findPos(document.getElementById(destination)));
      if(demo){
        this.discuss = 'Hello, I would like to request a demonstration.';
      }
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        api
          .submit(
            this.fullName,
            this.email,
            this.companySize,
            this.phoneNumber,
            this.discuss
          )
          .then((res) => {
            this.loading = false;
            if (res.data.responseCode === 1) {
              this.$refs.form.reset();
              this.text = "Your messange has been sent successfully";
            }
          })
          .catch((error) => {
            this.errorText = error;
            this.loading = false;
          });
      }
    },
  },
  computed: {
    chunkedBenefits() { 
      const chunks = [];
      const data = this.content.benefit_section.benefit;
      for (let i = 0; i < data.length; i += 2) {
        chunks.push(data.slice(i, i + 3));
      }
      return chunks;
    },
    chunkedFeatures(){
      const chunks = [];
      const data = this.content.feature_section.feature_component;
      for (let i = 0; i < data.length; i += 2) {
        chunks.push(data.slice(i, i + 3));
      }
      return chunks;
    },
    fontSize() {},
    homeFooterItemCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
    softwareCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 12;
        }
        case "lg": {
          return 6;
        }
        case "xl": {
          return 6;
        }
      }
    },
    AboutItemCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 6;
        }
        case "lg": {
          return 6;
        }
        case "xl": {
          return 6;
        }
      }
    },
    cardCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
    formCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 6;
        }
        case "lg": {
          return 6;
        }
        case "xl": {
          return 6;
        }
      }
    },
    demoBriefCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 6;
        }
        case "lg": {
          return 6;
        }
        case "xl": {
          return 6;
        }
      }
    },
    breakPoint() {
      return this.$vuetify.breakpoint.name;
    },
    navCol() {
      if (this.$vuetify.breakpoint.width >= 1023) {
        return 6;
      } else {
        return 12;
      }
    },
  },
};
