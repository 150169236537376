import axios from "@/plugins/http.service.js";

export default {
  gettenants() {
    return axios.get(`tenant/get`);
  },

  add(name, created_date, type = null) {
    var data = {
      name: name,
      created_date,
      type: type,
    };
    return axios.post("tenant/add", data);
  },

  save(id, name) {
    var data = {
      id: id,
      name: name,
    };
    return axios.post("tenant/save", data);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("tenant/archive", data);
  },


  save_all_settings(data) {
    return axios.post("tenant-settings/save-all", data);
  },


  get_setting(id) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    return axios.get(
      `tenant-settings/get-settings?tenant_id=${id ? id : tenant_id}`
    );
  },
};
